@import 'src/global.scss';

@include keyframes(splash) {
  0% {
    opacity: 0;
    filter: brightness(1);
    transform: scale(1.0);
  }
  50% {
    opacity: 1;
    filter: brightness(1.3);
    transform: scale(1.25);
  }
  100% {
    opacity: 1;
    filter: brightness(1);
    transform: scale(1.0);
  }
}
.splashScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include animate(splash, 1.0s, 0.0s);
  background: white;
  >span {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 200px;
    height: 200px;
  }
}