@import 'src/global.scss';
$red: #CC0033;
@include keyframes(scaleDown) {
  0% {
    transform: scale(10);
  }
  100% {
    transform: scale(1);
  }
}
@include keyframes(fadeon) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@include keyframes(fadeon-delay) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.captionLayer {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  >div {
    width: 100%;
    height: 100%;
    @include animate(fadeon, 1.0s, 0.0s);
  }
  :global {
    .bubble {
      font-family: "Metropolis-Black", serif;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease-out;
      height: 30px;
      width: 30px;
      flex:1;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      z-index: 3;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 10px;
      flex-wrap: nowrap;
      white-space: nowrap;
      >div {
        display: flex;
        flex: 1;
      }
      .right {
        justify-content:center;
        align-items:flex-start;
        flex-direction: column;
        position: relative;
        max-width: 15px;
        >div {
          margin-left:-15px;
          margin-top:-42px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          max-width: 260px;
          height: 100%;
          padding: 2px;
          padding-left: 4px;
          padding-right: 4px;
          background: $red;
          position: relative;
          &::before {
            content: '';
            z-index: -2;
            position: absolute;
            border-radius: 15px;
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          }
          &::after {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
            content: '';
            background: $red;
            position: absolute;
            width: 20px;
            height: 20px;
            bottom: -6px;
            left: 20px; /*30%;*/
            transform: translateX(-15px) translateY(-2px) scaleX(0.7) rotate(45deg);
            border-bottom-right-radius: 3px;
            z-index: -1;
          }
          // -webkit-font-smoothing: subpixel-antialiased;
          // transform: translateZ(0);
          // -webkit-filter: blur(0px);
          // filter: none;

          span {
          //@include animate(scaleDown, 0.1s, 0.0s);
          padding-left: 4px;
            padding-right: 4px;
            &:nth-child(1) {
              border: 2px solid white;
              border-radius: 50%;
              text-transform: lowercase;
              width: calc(16px - 8px);
              height: 16px;
              text-align: center;
              line-height: 18px;
              font-size: 12px;
            }
            &:nth-child(2) {
              text-align: center;
            }
            color: white;
            word-break: keep-all;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }
  }
}
.roomNameLayer {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  >div {
    width: 100%;
    height: 100%;
    @include animate(fadeon, 0.5s, 0.0s);
  }
  :global {
    .bubble {
      font-family: "Metropolis-Black", serif;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease-out;
      height: 30px;
      width: 30px;
      flex:1;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      z-index: 3;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 10px;
      flex-wrap: nowrap;
      white-space: nowrap;
      >div {
        flex: 1;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        max-width: 260px;
        height: calc(100% - 8px);
        padding: 5px;
        background: $red;
        position: relative;
        &::before {
          content: '';
          z-index: -2;
          position: absolute;
          border-radius: 25px;
          width: 100%;
          height: 100%;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        }
        &::after {
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          content: '';
          background: $red;
          position: absolute;
          width: 20px;
          height: 20px;
          bottom: -6px;
          left: calc(50% + 7px);
          transform: translateX(-15px) translateY(-2px) scaleX(0.7) rotate(45deg);
          border-bottom-right-radius: 3px;
          z-index: -1;
        }
        span {
          padding-left: 4px;
          padding-right: 4px;
          text-align: center;
          color: white;
          word-break: keep-all;
          white-space: nowrap;
          display: inline-block;
        }
      }
      &.force-white {
        >div {
          background: white;
          &::after {
            background: white;
          }
          span {
            color: #999999;
          }
        }
      }
    }
  }
}
